<template>
  <plc-page :slide="false" :pic-url="banner">
    <div class="fixed-width page-content page-contact">
      <div class="submenu-wrapper">
        <div class="item item-1 active">
          <div class="submenu-name">联系我们</div>
          <img class="submenu-arrow" src="../assets/images/arrow-down-white.png">
        </div>
      </div>
      <div class="description">
        <div class="item">
          <div class="key">公司名称</div>
          <div>普立兹智能系统有限公司</div>
        </div>
        <div class="item">
          <div class="key">咨询热线</div>
          <div>400-900-5780</div>
        </div>
        <div class="item">
          <div class="key">E-mail</div>
          <div>sales@plc-tek.com</div>
        </div>
        <div class="item">
          <div class="key">地址</div>
          <div>江苏省太仓市沙溪镇台北路6号</div>
        </div>
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'

  export default {
    name: 'Contact',
    components: {
      PlcPage
    },
    data () {
      return {
        banner: require('../assets/images/banner/banner-contact.jpg')
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-contact
    .description
      display flex
      flex-direction column
      align-items center
      .item
        display flex
        color #171717
        font-size 21px
        line-height 2
        width 20em
        .key
          position relative
          text-align justify
          text-align-last justify
          width 4.5em
          margin-right 1em
          &::after
            content: '：'
            position: absolute
            right: -1em
</style>
